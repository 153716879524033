<template>
  <div>
    <div class="card-header">
      <h4>Export PDF</h4>
    </div>
    <div class="card-body">
      <div class="form-row">
        <div class="col">
          <label>Start At</label>
          <input
            type="date"
            class="form-control"
            v-model="form_export.start_at"
          />
        </div>
        <div class="col">
          <label>End At</label>
          <input
            type="date"
            class="form-control"
            v-model="form_export.end_at"
          />
        </div>
        <div class="col">
          <label>Payment Type</label>
          <select
            v-model="form_export.payment_type_id"
            class="form-control"
            id=""
          >
            <option value>--Select Payment Type--</option>
            <option
              v-for="paymentType in paymentTypes"
              :key="paymentType.id"
              :value="paymentType.id"
              >{{ paymentType.name }}</option
            >
          </select>
        </div>
      </div>
      <div class="text-right">
        <button
          class="btn btn-primary"
          @click="export_pdf"
          :disabled="form_export.start_at == '' || form_export.end_at == ''"
        >
          Export PDF
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ExportPdf",
  props: ["payments"],
  data() {
    return {
      form_export: {
        start_at: "",
        end_at: "",
        payment_type_id: "",
      },
      paymentTypes: this.payments,
    };
  },
  methods: {
    export_pdf() {
      axios({
        url: this.$store.state.api + "petty_cash/export",
        method: "GET",
        responseType: "blob",
        Authorization: this.$store.state.token,
        params: {
          start_at: this.form_export.start_at,
          end_at: this.form_export.end_at,
          payment_type_id: this.form_export.payment_type_id,
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "petty_cash.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
};
</script>
